import { ApplicationRef, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication, enableDebugTools, provideClientHydration, withI18nSupport } from '@angular/platform-browser';
import { TransferState } from '@angular/core';
import { provideRouter } from '@angular/router';
import { NgxEchartsModule } from 'ngx-echarts';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

{/* Translation Imports */}
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { TranslateBrowserLoader } from './app/translate-browser-loader';

import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';

export function HttpLoaderFactory(httpClient: HttpClient) {
  const timestamp = new Date().getTime();
  return new TranslateHttpLoader(httpClient, './assets/translations/', `.json?v=${timestamp}`);
}

registerLocaleData(localeNl);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes),
    provideClientHydration(withI18nSupport()),
    provideHttpClient(),
    { provide: TransferState, useClass: TransferState },
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: TranslateBrowserLoader,
          deps: [HttpClient, TransferState],
        },
      }),
      NgxEchartsModule.forRoot({
        echarts: () => import('echarts')
      })
    ),   
  ],
})
.then(moduleRef => {
  const applicationRef = moduleRef.injector.get(ApplicationRef);
  const componentRef = applicationRef.components[0];
  enableDebugTools(componentRef);
})
.catch((err) => {
  console.error(err);
});